import React from "react";
import { useProcedures } from "../../hooks/useProcedures";
import ProcedureFilters from "../../../../../components/ProcedureFilters/ProcedureFilters";
import { Empty } from "../../../../../../../shared/Empty/Empty";
import { QualiphyExamProcedure } from "../../../../../components/QualiphyExamProcedure/QualiphyExamProcedure";
import CosmeticTimeline from "../../../../../Timeline/CosmeticTimeline";
import HealthTimeline from "../../../../../Timeline/HealthTimeline";
import { LoadMoreObserver } from "../../../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { Header } from "../Header";

export function Procedures({
  patientId,
  viewNotes,
  openProcedureDetail,
  openLightbox,
  runCarousel,
  viewFilledSurvey,
  showSendInstructionPopup,
  viewQuestionnaires,
  viewBAVideos,
  viewTraceAbility,
  goToInvoicePage,
  viewConsents,
  getInvoiceDetails,
  handleEprescribe,
  handleChildState,
  viewProcedureDetail,
  showPrescriptionNew,
  viewHealthTraceability,
  onChangeType,
  type,
  profile,
  onCreateAppointment,
  onCreateProcedure,
  isQualiphyEnabled,
}) {
  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isLoading,
    refetch,
    procedurePeriod,
    categoryFilter,
    providerFilter,
    search,
    resetFilters,
  } = useProcedures(patientId);

  return (
    <>
      <Header
        onChangeType={onChangeType}
        type={type}
        profile={profile}
        onCreateAppointment={onCreateAppointment}
        onCreateProcedure={onCreateProcedure}
        patientId={patientId}
        isQualiphyEnabled={isQualiphyEnabled}
        refetchProcedures={refetch}
      />
      <ProcedureFilters
        period={procedurePeriod.value}
        handlePeriod={procedurePeriod.update}
        searchValue={search.value}
        onChangeSearch={search.update}
        categoryFilter={categoryFilter}
        providerFilter={providerFilter}
        handleResetFilters={resetFilters}
      />
      <div id="Client_Procedure_Timeline" className="time-line">
        {isLoading && (
          <Empty position="center" size="small">
            Loading procedures...
          </Empty>
        )}
        {!isLoading && !data?.length && (
          <Empty position="center" size="small">
            No procedures found
          </Empty>
        )}
        {data?.length > 0 && (
          <div>
            {data.map((hobj, hidx) => (
              <div
                key={"single-timeline-" + hidx}
                className="row single-timeline-wrapper"
              >
                {hobj.procedure_type === "exam" && (
                  <QualiphyExamProcedure
                    procedure={hobj}
                    viewNotes={() => viewNotes(hobj.id)}
                    refetchProcedures={refetch}
                  />
                )}
                {hobj.procedure_type === "cosmetic" && (
                  <CosmeticTimeline
                    hobj={hobj}
                    openProcedureDetail={openProcedureDetail}
                    openLightbox={openLightbox}
                    runCarousel={runCarousel}
                    viewFilledSurvey={viewFilledSurvey}
                    showSendInstructionPopup={showSendInstructionPopup}
                    viewQuestionnaires={viewQuestionnaires}
                    viewBAVideos={viewBAVideos}
                    viewTraceAbility={viewTraceAbility}
                    viewNotes={viewNotes}
                    goToInvoicePage={goToInvoicePage}
                    viewConsents={viewConsents}
                    getInvoiceDetails={getInvoiceDetails}
                    handleEprescribe={handleEprescribe}
                    refetchProcedures={refetch}
                    handleChildState={handleChildState}
                  />
                )}
                {hobj.procedure_type === "health" && (
                  <HealthTimeline
                    hobj={hobj}
                    hidx={hidx}
                    openProcedureDetail={openProcedureDetail}
                    showSendInstructionPopup={showSendInstructionPopup}
                    viewProcedureDetail={viewProcedureDetail}
                    viewQuestionnaires={viewQuestionnaires}
                    viewConsents={viewConsents}
                    showPrescriptionNew={showPrescriptionNew}
                    viewHealthTraceability={viewHealthTraceability}
                    goToInvoicePage={goToInvoicePage}
                    viewNotes={viewNotes}
                    handleEprescribe={handleEprescribe}
                    refetchProcedures={refetch}
                  />
                )}
              </div>
            ))}
            {hasNextPage && (
              <LoadMoreObserver
                center
                loadMore={fetchNextPage}
                isLoading={isFetching}
                hasMore={hasNextPage}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}
