import React from "react";
import { useCurrentAccountQuery } from "../../../../../api/queries/useAccountQuery";
import { PLAN_CODES } from "../../../../../consts/api";
import { ScrollBox } from "./components/ScrollBox.js";
import { PrintArea } from "./components/PrintArea.js";
import { Procedures } from "./components/Procedures/Procedures.js";
import { Appointments } from "./components/Appointments/Appointments.js";

export function Timeline({
  type,
  onChangeType,
  profile,
  onCreateAppointment,
  onCreateProcedure,
  isQualiphyEnabled,
  patientId,
  monthArray,
  openProcedureDetail,
  openLightbox,
  runCarousel,
  viewFilledSurvey,
  showSendInstructionPopup,
  viewQuestionnaires,
  viewBAVideos,
  viewTraceAbility,
  viewNotes,
  viewConsents,
  getInvoiceDetails,
  handleChildState,
  handleEprescribe,
  viewProcedureDetail,
  showPrescriptionNew,
  viewHealthTraceability,
  goToInvoicePage,
  getPrescribedPlanData,
  refetchClientData,
  showProgramDetails,
  cancelPlanByID,
  getPlanDetailsByID,
  performThisAction,
  viewBreakDown,
  startProTotal,
  startProMonthly,
  changePlanCard,
}) {
  const { data: account } = useCurrentAccountQuery();

  const isTouchMd = account?.preferences?.isTouchMd;
  const planCode = account?.subscription?.planCode;

  const isTouchMdTimeline = isTouchMd && planCode === PLAN_CODES.booking;

  const isProceduresTimeline =
    !isTouchMdTimeline && ["procedure", "cosmetic", "health"].includes(type);

  return (
    <div className="patient-right col-lg-8">
      <div className="merge-setion">
        {isProceduresTimeline && (
          <Procedures
            patientId={patientId}
            viewNotes={viewNotes}
            openProcedureDetail={openProcedureDetail}
            openLightbox={openLightbox}
            runCarousel={runCarousel}
            viewFilledSurvey={viewFilledSurvey}
            showSendInstructionPopup={showSendInstructionPopup}
            viewQuestionnaires={viewQuestionnaires}
            viewBAVideos={viewBAVideos}
            viewTraceAbility={viewTraceAbility}
            goToInvoicePage={goToInvoicePage}
            viewConsents={viewConsents}
            getInvoiceDetails={getInvoiceDetails}
            handleEprescribe={handleEprescribe}
            handleChildState={handleChildState}
            viewProcedureDetail={viewProcedureDetail}
            showPrescriptionNew={showPrescriptionNew}
            viewHealthTraceability={viewHealthTraceability}
            onChangeType={onChangeType}
            type={type}
            profile={profile}
            onCreateAppointment={onCreateAppointment}
            onCreateProcedure={onCreateProcedure}
            isQualiphyEnabled={isQualiphyEnabled}
          />
        )}
        {isTouchMdTimeline && (
          <Appointments
            patientId={patientId}
            viewQuestionnaires={viewQuestionnaires}
            viewConsents={viewConsents}
            onChangeType={onChangeType}
            type={type}
            profile={profile}
            onCreateAppointment={onCreateAppointment}
            onCreateProcedure={onCreateProcedure}
            isQualiphyEnabled={isQualiphyEnabled}
          />
        )}
        {type === "treatmentPlan" &&
          profile?.priscribed_treatment_plans?.length > 0 && (
            <ScrollBox
              profile={profile}
              getPrescribedPlanData={getPrescribedPlanData}
            />
          )}
        {type === "treatmentPlan" && profile.active_treamtment_plan === 1 && (
          <PrintArea
            profile={profile}
            refetchClientData={refetchClientData}
            showProgramDetails={showProgramDetails}
            cancelPlanByID={cancelPlanByID}
            getPlanDetailsByID={getPlanDetailsByID}
            performThisAction={performThisAction}
            monthArray={monthArray}
            viewBreakDown={viewBreakDown}
            startProTotal={startProTotal}
            startProMonthly={startProMonthly}
            changePlanCard={changePlanCard}
          />
        )}
      </div>
    </div>
  );
}
