import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export function listSMS(formData) {
  return (dispatch) => {
    http
      .get("get-sms-notifications", formData)
      .then((response) => {
        dispatch({ type: "LIST_SMS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "LIST_SMS", payload: error.response.data });
      });
  };
}

export function searchPhoneNumber(formData) {
  return async (dispatch) => {
    return http
      .post("search-phone-number", formData)
      .then((response) => {
        dispatch({ type: "SEARCH_PHONE_NUMBER", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SEARCH_PHONE_NUMBER", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function searchPatientByName(formData) {
  return async (dispatch) => {
    return http
      .get("search-client?letter_key=" + formData.client)
      .then((response) => {
        dispatch({ type: "SEARCH_PATIENTS", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SEARCH_PATIENTS", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export const fetchSMSNotificationsPopups = (formData) => {
  return async (dispatch) => {
    return http
      .get("get-patient", formData)
      .then((response) => {
        dispatch({ type: "SELECTED_SMS_POPUPS", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SELECTED_SMS_POPUPS", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
};

export const createReply = (formData) => {
  return (dispatch) => {
    http
      .post("send-sms-reply", formData)
      .then((response) => {
        dispatch({ type: "CREATE_REPLY_INBOX", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_REPLY_INBOX", payload: error.response.data });
      });
  };
};

export function getOptionAndList(type, formData) {
  formData = formData || {};

  return (dispatch) => {
    http
      .post("sms-list/" + type, formData)
      .then((response) => {
        dispatch({ type: "GET_SMS_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_SMS_LIST", payload: error.response.data });
      });
  };
}

export function getOptionsOnly(id, tId) {
  if (!tId) tId = 0;

  return (dispatch) => {
    http
      .get("get-variable/" + id + "/" + tId)
      .then((response) => {
        dispatch({ type: "GET_OPTIONS_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_OPTIONS_LIST", payload: error.response.data });
      });
  };
}

export function saveSmsFilterReport(formData) {
  return (dispatch) => {
    http
      .post("save-sms-list-filter-report", formData)
      .then((response) => {
        dispatch({ type: "SAVE_SMS_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SAVE_SMS_LIST", payload: error.response.data });
      });
  };
}

export function getFilterReportData(id, formData) {
  return (dispatch) => {
    http
      .post("get-sms-list-filter-report/" + id, formData)
      .then((response) => {
        if (formData.is_download && formData.is_download == 1) {
          dispatch({ type: "DOWNLOAD_EXCEL_FILE", payload: response.data });
        } else {
          dispatch({ type: "GET_LIST_DATA", payload: response.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "GET_LIST_DATA", payload: error.response.data });
      });
  };
}

export function deleteList(formData) {
  return (dispatch) => {
    http
      .post("delete-sms-list-filter-report", formData)
      .then((response) => {
        dispatch({ type: "DELETE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_DATA", payload: error.response.data });
      });
  };
}

export function getSmsCampaignList(formData) {
  return (dispatch) => {
    http
      .post("get-sms-campaign-list", formData)
      .then((response) => {
        dispatch({ type: "GET_SMS_CAMPAIGN_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_SMS_CAMPAIGN_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function createSmsCampaign(formData) {
  return (dispatch) => {
    http
      .post("save-sms-campaign", formData)
      .then((response) => {
        dispatch({ type: "CREATE_SMS_CAMPAIGN_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_SMS_CAMPAIGN_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function getSmsCampaignById(id) {
  return (dispatch) => {
    http
      .get("get-sms-campaign-by-id/" + id)
      .then((response) => {
        dispatch({ type: "EDIT_SMS_CAMPAIGN_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "EDIT_SMS_CAMPAIGN_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function getSMSTemplateData(formData) {
  return (dispatch) => {
    http
      .post("get-sms-template-list", formData)
      .then((response) => {
        dispatch({ type: "GET_SMS_TEMPLATE_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_SMS_TEMPLATE_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export function addNewSMSTemplate(formData) {
  return (dispatch) => {
    http
      .post("save-sms-template", formData)
      .then((response) => {
        dispatch({ type: "ADD_NEW_SMS_TEMPLATE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "ADD_NEW_SMS_TEMPLATE",
          payload: error.response.data,
        });
      });
  };
}

export function deleteCampaign(id) {
  return (dispatch) => {
    http
      .get("delete-sms-campaign/" + id)
      .then((response) => {
        dispatch({ type: "DELETE_CAMPAIGN", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_CAMPAIGN", payload: error.response.data });
      });
  };
}

export function getDataViaId(id) {
  return (dispatch) => {
    http
      .get("get-sms-template-by-id/" + id)
      .then((response) => {
        dispatch({ type: "GET_SMS_TEMPLATE_BY_ID", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_SMS_TEMPLATE_BY_ID",
          payload: error.response.data,
        });
      });
  };
}

export function deleteSMSID(id) {
  return (dispatch) => {
    http
      .get("delete-sms-template/" + id)
      .then((response) => {
        dispatch({ type: "DELETE_SMS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_SMS", payload: error.response.data });
      });
  };
}

export function confirmNumber(formData) {
  return async (dispatch) => {
    return http
      .post("save-twillio-number", formData)
      .then((response) => {
        dispatch({ type: "SAVE_TWILIO_NUMBER", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SAVE_TWILIO_NUMBER", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function smsReadUnread(formData) {
  return (dispatch) => {
    http
      .put("sms-read-unread", formData)
      .then((response) => {
        dispatch({ type: "SMS_READ_UNREAD", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SMS_READ_UNREAD", payload: error.response.data });
      });
  };
}
