import React, { useMemo } from "react";
import cx from "clsx";
import InputMask from "react-input-mask";
import moment from "moment";
import styles from "../../sass/components/GfeInputs.module.scss";
import { Select } from "../../../../shared/Select/Select";
import { US_STATES } from "../../../../consts/general";
import { ProviderSingleDateCalendar } from "../../../../Components/Dashboard/ProviderDashboard/components/ProviderSingleDateCalendar";

const GfeInputs = ({
  dob,
  handleDob,
  state,
  handleState,
  hasDobError,
  hasStateError,
}) => {
  const statesOptions = useMemo(
    () =>
      US_STATES.map((state) => ({
        value: state.abbr,
        label: state.name,
      })),
    [US_STATES],
  );

  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.gfeDobInput, { [styles.error]: hasDobError })}>
        <InputMask
          name="dob"
          value={moment(dob).isValid() ? moment(dob).format("MM/DD/YYYY") : dob}
          mask="99/99/9999"
          placeholder="Date of Birth"
          onChange={(e) => {
            handleDob(e.target.value);
          }}
        />
        <ProviderSingleDateCalendar
          className={cx(styles.dobCalendar)}
          selectedDate={
            dob
              ? moment(dob).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD")
          }
          onChange={(value) => handleDob(moment(value).format("MM/DD/YYYY"))}
          hideValue
        />
      </div>

      <Select
        placeholder={"State"}
        options={statesOptions}
        value={state}
        className={cx(styles.gfeStateSelect)}
        onChange={(option) => {
          handleState(option);
        }}
        isSearchable
        isError={hasStateError}
      />
    </div>
  );
};

export default GfeInputs;
