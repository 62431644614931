import React from "react";
import cx from "clsx";
import Skeleton from "react-loading-skeleton";
import { AngleDownIcon } from "../../../../../assets/Icons/AngleDownIcon";
import classes from "../scss/repeatMd.module.scss";
import styles from "../scss/RepeatMdMembershipConfiguration.module.scss";
import { Input } from "../../../../../shared/Input/Input";
import MembershipConfigurationInput from "./MembershipConfigurationInput";
import { useRepeatMdMemberships } from "../hooks/useRepeatMdMemberships";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";

const RepeatMdMembershipConfiguration = ({ expanded, setExpanded }) => {
  const { data, isLoading } = useRepeatMdMemberships();

  return (
    <div
      className={cx(
        classes.expandButtonContainer,
        expanded.membershipConfiguration && classes.active,
      )}
    >
      <button
        className={classes.expandListButton}
        onClick={() =>
          setExpanded({
            ...expanded,
            membershipConfiguration: !expanded.membershipConfiguration,
          })
        }
      >
        <span>RepeatMD Membership Configuration</span>
        <span
          className={cx(
            classes.expandIcon,
            expanded.membershipConfiguration && classes.expanded,
          )}
        >
          <AngleDownIcon width={14} height={14} fill={"#667680"} />
        </span>
      </button>
      {expanded.membershipConfiguration && (
        <div>
          {!isLoading ? (
            data?.memberships?.map((membership) => (
              <div key={membership.id}>
                <div className={styles.inputFieldRow}>
                  <Input
                    className={styles.membershipInput}
                    size="small"
                    value={membership.name}
                    isReadonly
                  />
                  <MembershipConfigurationInput
                    membership={{
                      id: membership.id,
                      color: membership.badge_color,
                    }}
                  />
                </div>
                <div className={styles.benefitsContainer}>
                  <InputLabel>Benefits</InputLabel>
                  <ul className={styles.benefitsContainerList}>
                    {membership.data?.benefits?.map((benefit) => (
                      <li key={benefit}>{benefit}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))
          ) : (
            <Skeleton height={36} count={5} />
          )}
        </div>
      )}
    </div>
  );
};

export default RepeatMdMembershipConfiguration;
