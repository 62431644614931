import React from "react";
import { useAppointments } from "../../hooks/useAppointments";
import { Empty } from "../../../../../../../shared/Empty/Empty";
import TouchMDTimeline from "../../../../../TouchMDAppointments/TouchMD-appointments";
import { LoadMoreObserver } from "../../../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { Header } from "../Header";

export function Appointments({
  patientId,
  viewQuestionnaires,
  viewConsents,
  onChangeType,
  type,
  profile,
  onCreateAppointment,
  onCreateProcedure,
  isQualiphyEnabled,
}) {
  const { data, hasNextPage, fetchNextPage, isFetching, isLoading, refetch } =
    useAppointments(patientId);

  return (
    <>
      <Header
        onChangeType={onChangeType}
        type={type}
        profile={profile}
        onCreateAppointment={onCreateAppointment}
        onCreateProcedure={onCreateProcedure}
        patientId={patientId}
        isQualiphyEnabled={isQualiphyEnabled}
        refetchProcedures={refetch}
      />
      <div id="Client_Procedure_Timeline" className="time-line">
        {isLoading && (
          <Empty position="center" size="small">
            Loading appointments...
          </Empty>
        )}
        {!isLoading && !data?.length && (
          <Empty position="center" size="small">
            No appointments found
          </Empty>
        )}
        <div>
          {data?.length > 0 &&
            data.map((hobj, hidx) => (
              <div
                key={"single-timeline-" + hidx}
                className="row single-timeline-wrapper"
              >
                <TouchMDTimeline
                  hobj={hobj}
                  viewQuestionnaires={viewQuestionnaires}
                  viewConsents={viewConsents}
                />
              </div>
            ))}
        </div>
        {hasNextPage && (
          <LoadMoreObserver
            center
            loadMore={fetchNextPage}
            isLoading={isFetching}
            hasMore={hasNextPage}
          />
        )}
      </div>
    </>
  );
}
