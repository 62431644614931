import React, { useState } from "react";
import { Modal } from "../../../../../shared/Modal/Modal";
import { Button } from "../../../../../shared/Button/Button";
import styles from "./styles.module.scss";
import { Select } from "../../../../../shared/Select/Select";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { AlertBox } from "../../../../../shared/AlertBox/AlertBox";
import { Loading } from "../../../../../boxes/Loading/Loading";
import { useClinicOptions } from "./hooks/useClinicOptions";
import { useExport } from "./hooks/useExport";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";

export function Export({ exportParams }) {
  var { 0: isOpen, 1: setIsOpen } = useState(false);
  var { 0: selectedClinics, 1: setSelectedClinics } = useState([]);

  var clinicOptions = useClinicOptions();

  var exportInventory = useExport({
    exportParams,
    onSuccess: () => {
      setIsOpen(false);
      setSelectedClinics([]);
    },
  });

  return (
    <>
      <button className="new-blue-btn m-l-5" onClick={() => setIsOpen(true)}>
        Export
      </button>
      <Modal
        shouldCloseOnOverlayClick={false}
        footerNoBorder={true}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        header={<Modal.Title>Export Inventory</Modal.Title>}
        footer={
          <div className={styles.footer}>
            <Button variant="outlined" onClick={() => setIsOpen(false)}>
              Close
            </Button>
            <Button
              isDisabled={clinicOptions.isLoading || exportInventory.isLoading}
              onClick={() => exportInventory.initiate(selectedClinics)}
              leftAdornment={
                exportInventory.isLoading ? (
                  <CircularProgress size="small" color="white" />
                ) : undefined
              }
            >
              Export
            </Button>
          </div>
        }
      >
        {clinicOptions.isLoading ? (
          <Loading />
        ) : (
          <div className={styles.content}>
            <div>
              <InputLabel>Select clinics</InputLabel>
              <Select
                isMulti
                isClearable
                isSearchable
                options={clinicOptions.data}
                value={selectedClinics}
                onChange={(x) => setSelectedClinics(x)}
              />
              {selectedClinics.length !== clinicOptions.data.length && (
                <Button
                  size="small"
                  variant="outlined"
                  className={styles.selectAll}
                  onClick={() => setSelectedClinics(clinicOptions.data)}
                >
                  Select all
                </Button>
              )}
            </div>
            <AlertBox variant="warn" size="small">
              We will send a separate CSV per clinic to your email after export
              is done.
            </AlertBox>
          </div>
        )}
      </Modal>
    </>
  );
}
