import React from "react";
import cx from "clsx";
import { AngleRightIcon } from "../../../../assets/Icons/AngleRightIcon";
import styles from "./NavigationArrows.module.scss";

export function NavigationArrows({
  children,
  prevNavFunction,
  nextNavFunction,
  canGoPrev = true,
  canGoNext = true,
}) {
  return (
    <div className={styles.arrowsWrapper}>
      <div className={styles.btnWrapper}>
        {canGoPrev && (
          <button
            name="getPrev"
            type="button"
            onClick={prevNavFunction}
            className={cx(styles.arrowBtn, styles.arrowLeft)}
          >
            <div className={cx(styles.arrowMirrored, styles.arrow)}>
              <AngleRightIcon />
            </div>
          </button>
        )}
      </div>
      {children}
      <div className={styles.btnWrapper}>
        {canGoNext && (
          <button
            type="button"
            name="getNext"
            onClick={nextNavFunction}
            className={cx(styles.arrowBtn, styles.arrowRigth)}
          >
            <div className={styles.arrow}>
              <AngleRightIcon />
            </div>
          </button>
        )}
      </div>
    </div>
  );
}
