import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../../../../consts/api";
import { http } from "../../../../../../services/HttpService";
import { removeNullishFromShape } from "../../../../../../utilities/general";
import {
  getNextPaginationPage,
  composePaginationSchema,
} from "../../../../../../schemas/pagination";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";

const PAGE_SIZE = 15;

const composeResponse = (res) => {
  const appointments = res?.data?.data?.data || [];
  return {
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: PAGE_SIZE,
    }),
    appointments,
  };
};

export function useAppointments(patientId) {
  const { tCommon } = useAppTranslation.Common();

  const { data, hasNextPage, fetchNextPage, isFetching, refetch, isLoading } =
    useInfiniteQuery({
      queryKey: [QUERY_KEYS.touchMdTimeline, patientId],
      queryFn: async ({ pageParam = 1 }) => {
        const res = await http.get(HTTP_ENDPOINTS.getTouchMdTimeline(), {
          params: removeNullishFromShape({
            page: pageParam,
            pagesize: PAGE_SIZE,
            id: patientId,
          }),
        });
        return composeResponse(res);
      },
      onError: () => {
        uiNotification.error(tCommon("error.fetchTouchMdTimeline"));
      },
      staleTime: 0,
      refetchOnWindowFocus: true,
      getNextPageParam: getNextPaginationPage,
    });

  const appointments = useMemo(() => {
    if (data?.pages) {
      return data.pages
        .map((p) => p.appointments)
        .reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data?.pages]);

  return {
    data: appointments,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isLoading,
    refetch,
  };
}
