import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  mdNote: yup.string().required(),
  procedureId: yup.number().required(),
});

export function useRequestInformationFromProviderMutation({
  onSuccess,
  onError,
}) {
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (dto) => {
      const req = requestSchema.validateSync(dto, { strict: true });

      return http.post(HTTP_ENDPOINTS.postRequestInformationFromProvider(), {
        procedure_id: req.procedureId,
        md_note: req.mdNote,
      });
    },
    onError: () => {
      onError?.();
    },
    onSuccess: () => {
      onSuccess?.();
    },
  });

  return {
    initiate: async (dto) => await mutateAsync(dto),
    isLoading,
  };
}
