import { REDUCER_NAME } from "./consts";

export const selectors = {
  selectSignProceduresData: (state) => state[REDUCER_NAME].signProceduresData,
  selectProcedureSigningStatus: (state) =>
    Boolean(state[REDUCER_NAME].isSigning),
  selectProcedureSignedStatus: (state) => Boolean(state[REDUCER_NAME].isSigned),
  selectProceduresSignList: (state) =>
    state[REDUCER_NAME].proceduresListForSign,
};
