import { APP_LANGUAGES, I18N_NAMESPACES } from "../consts/i18n";
import enCommon from "./en/common.json";
import enAuth from "./en/auth.json";
import enSettings from "./en/settings.json";
import enBusinessInsights from "./en/businessInsights.json";
import enSales from "./en/sales.json";
import enInbox from "./en/inbox.json";
import enClients from "./en/clients.json";
import enAppointments from "./en/appointments.json";
import enInventory from "./en/inventory.json";
import enSmartConfig from "./en/smartConfig.json";
import enDashboard from "./en/dashboard.json";
import enPrescriptions from "./en/prescriptions.json";
import enProviderRoom from "./en/providerRoom.json";

export const resources = {
  [APP_LANGUAGES.en]: {
    [I18N_NAMESPACES.common]: enCommon,
    [I18N_NAMESPACES.auth]: enAuth,
    [I18N_NAMESPACES.settings]: enSettings,
    [I18N_NAMESPACES.businessInsights]: enBusinessInsights,
    [I18N_NAMESPACES.sales]: enSales,
    [I18N_NAMESPACES.inbox]: enInbox,
    [I18N_NAMESPACES.clients]: enClients,
    [I18N_NAMESPACES.appointments]: enAppointments,
    [I18N_NAMESPACES.inventory]: enInventory,
    [I18N_NAMESPACES.smartConfig]: enSmartConfig,
    [I18N_NAMESPACES.dashboard]: enDashboard,
    [I18N_NAMESPACES.prescriptions]: enPrescriptions,
    [I18N_NAMESPACES.providerRoom]: enProviderRoom,
  },
};
