import React, { useState } from "react";
import PropTypes from "prop-types";
import parentClasses from "../../sass/ProviderRoom.module.scss";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Button } from "../../../../shared/Button/Button";
import { useTableInfo } from "../hooks/useTableInfo";
import { ProcedureTabs } from "../../components/ProcedureTabs/ProcedureTabs";
import { PROVIDER_ROOM_PROCEDURE_TYPE } from "../../../../api/queries/useProviderRoomProceduresQuery";
import { Table } from "../../../../shared/Table/Table";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import ReactSelect from "../../../../Components/Common/ReactSelect";
import { ModalNotes } from "../../components/ModalNotes/ModalNotes";

export function TabInformation({
  onChangeTab,
  isVisible,
  procedures,
  unsend,
  isUnsending,
  availableClinicsList = [],
  onChangeClinic,
  clinicId,
  showHideActionButton,
  providerUserId,
}) {
  const { tCommon } = useAppTranslation.Common();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [notes, setNotes] = useState({});

  const openRequestModal = (notes) => {
    setNotes(notes);
    setIsNotesOpen(true);
  };

  const { cols, data, selected, resetSelected } = useTableInfo({
    procedures,
    showHideActionButton,
    providerUserId,
    openRequestModal,
  });

  const handleChangeTab = (type) => {
    onChangeTab(type);
    resetSelected();
    onChangeClinic("");
  };

  const handleUnsend = () => {
    setIsConfirmModalOpen(false);
    unsend({ procedureIds: selected }).then(resetSelected);
  };

  const handleClinicChange = ({ id }) => {
    if (id) {
      onChangeClinic(id.toString());
    }
  };

  const list = [{ name: "All Clinics", id: "" }, ...availableClinicsList];

  return (
    <>
      <div className={parentClasses.procedureTabsRow}>
        <ProcedureTabs
          value={PROVIDER_ROOM_PROCEDURE_TYPE.information}
          isInfoTabVisible={1}
          onChange={handleChangeTab}
        />
        <div className="col-12 col-sm-5 col-md-4 col-lg-3 p-r-0">
          <ReactSelect
            name={"id"}
            selectedId={clinicId}
            list={list}
            label={"name"}
            value={"id"}
            handleReactSelect={handleClinicChange}
            isValid={true}
            placeholder={"Select Clinic"}
            isMulti={false}
          />
        </div>
        {!showHideActionButton && (
          <Button
            size="small"
            onClick={() => setIsConfirmModalOpen(true)}
            isDisabled={selected.length === 0 || isUnsending}
            leftAdornment={
              isUnsending ? (
                <CircularProgress size="tiny" color="white" />
              ) : undefined
            }
          >
            {tCommon("label.unsend")} ({selected.length})
          </Button>
        )}
      </div>
      {isVisible && (
        <Table
          bgColor="white"
          cols={cols}
          data={data}
          headClassName={parentClasses.tableHead}
        />
      )}
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onCancel={() => setIsConfirmModalOpen(false)}
        onConfirm={handleUnsend}
      >
        {tCommon("providerRoom.confirm.unsendFromMd")}
      </ConfirmModal>

      <ModalNotes
        notes={notes}
        isOpen={isNotesOpen}
        onClose={() => setIsNotesOpen(false)}
      />
    </>
  );
}

TabInformation.propTypes = {
  onChangeTab: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  procedures: PropTypes.array.isRequired,
  unsend: PropTypes.func.isRequired,
  isUnsending: PropTypes.bool.isRequired,
};
