/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-children-prop */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useSpring, a, animated } from "react-spring";
import { isEqual } from "lodash";
import { useMeasure, usePrevious } from "./SidebarHelper";
import { mainAdminGuard } from "../../../Utils/authorization.js";
import { ROUTES, SETTINGS_ROUTES } from "../../../consts/routes.js";
import { CLIENT_GLOBAL_NAME, PERMISSIONS } from "../../../consts/api.js";
import {
  getIsPosEnabled,
  getIsAccountTypePaid,
  getIsAcceleratorPlan,
  getTimeClockSetting,
  getIsTouchMDPracticeBooking,
  featureToggleAccounts,
} from "../../../Utils/services.js";
import { LOCAL_STORAGE_KEYS } from "../../../consts/localStorage.js";
import { getIsMainAdmin } from "../../../utilities/localStorage.js";
import { tSettings } from "../../../i18n/useAppTranslation.js";
import { getIsEfaxIntegrationAccessible } from "../../../helpers/integrations.js";
import { isShopifyAvailable } from "../../../helpers/featureFlags.js";
import { withGlobalSettings } from "../../../hocs/withGlobalSettings.js";
import { withBetaFeatures } from "../../../hocs/withBetaFeatures.js";

const toggle = {
  marginTop: 0,
  cursor: "pointer",
  verticalAlign: "top",
  display: "inline-block",
};

const Tree = ({
  children,
  name,
  styleClass,
  styleLinkClass,
  childClass,
  navigateTo,
  activeMenu,
  parentIndex,
  isLocked = false,
}) => {
  const [isOpen, setOpen] = useState(activeMenu);
  const previous = usePrevious(isOpen);
  const [bind, { height: viewHeight }] = useMeasure();

  const { height, opacity, transform, visibility } = useSpring({
    from: {
      height: 0,
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      visibility: "hidden",
    },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
      transform: `translate3d(${isOpen ? 0 : 20}px,0,0)`,
      visibility: isOpen ? "visible" : "hidden",
    },
  });

  return (
    <li className={styleClass}>
      {navigateTo ? (
        navigateTo != "javascript:void(0);" ? (
          <Link className={styleLinkClass} to={navigateTo}>
            {" "}
            {name}
            {isLocked === true && (
              <span className="fa-lock-upgrade-plan">
                &nbsp;
                <i className="fa fa-lock" />
              </span>
            )}
          </Link>
        ) : (
          <a className={styleLinkClass}>{name}</a>
        )
      ) : (
        <a
          className={styleLinkClass}
          style={{ ...toggle, opacity: children ? 1 : 0 }}
          onClick={() => setOpen(!isOpen)}
          data-is-parent-sidebar={1}
          data-is-opened={isOpen ? 1 : 0}
          data-parent-index={parentIndex}
        >
          {name}
          {!navigateTo && isOpen && (
            <span
              style={{ ...toggle }}
              onClick={() => setOpen(!isOpen)}
              data-is-parent-sidebar={1}
              data-is-opened={isOpen ? 1 : 0}
              data-parent-index={parentIndex}
            >
              {" "}
              <i
                className="fas fa-angle-up"
                data-is-parent-sidebar={1}
                data-is-opened={isOpen ? 1 : 0}
                data-parent-index={parentIndex}
              ></i>{" "}
            </span>
          )}
          {!navigateTo && !isOpen && (
            <span
              style={{ ...toggle }}
              onClick={() => setOpen(!isOpen)}
              data-is-parent-sidebar={1}
              data-is-opened={!isOpen ? 1 : 0}
              data-parent-index={parentIndex}
            >
              {" "}
              <i
                className="fas fa-angle-down"
                data-is-parent-sidebar={1}
                data-is-opened={!isOpen ? 1 : 0}
                data-parent-index={parentIndex}
              ></i>{" "}
            </span>
          )}
        </a>
      )}
      <animated.div
        className={opacity == 0 ? "no-display frame-content" : "frame-content"}
        style={{
          opacity,
          height: isOpen && previous === isOpen ? "auto" : height,
          visibility,
        }}
      >
        <a.ul
          className={childClass}
          style={{ transform }}
          {...bind}
          children={children}
        />
      </animated.div>
    </li>
  );
};

const checkPermission = (globalPermission, specificPermission) => {
  return globalPermission.find((str) => str === specificPermission);
};

const getClientsLabel = () => {
  const globalLang =
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.languageData))?.global ||
    {};

  const clientAlias = globalLang?.header_clients;

  if (Object.values(CLIENT_GLOBAL_NAME).includes(clientAlias)) {
    return `${clientAlias}s`;
  }

  return CLIENT_GLOBAL_NAME.client;
};

const renderSidebar = (globalSettings, betaFeatures) => {
  const featureFlag = globalSettings?.feature_flag;
  const toggledOnBetaFutures = betaFeatures?.toggledOnBetaFeaturesLabels;

  let userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;

  let ePrescriptionSetup = localStorage.getItem("ePrescriptionSetup")
    ? JSON.parse(localStorage.getItem("ePrescriptionSetup"))
    : null;

  const userPermissions =
    userData && userData.permissions ? userData.permissions : [];

  let sideBarPermissions = [...userPermissions];

  let languageData = localStorage.getItem("languageData")
    ? JSON.parse(localStorage.getItem("languageData"))
    : null;

  languageData =
    languageData && languageData.settings ? languageData.settings : null;

  let sideBarContent = [];

  let accountSetupSubMenu = [];

  const canAccessStripeDocuments =
    checkPermission(sideBarPermissions, "view-delete-stripe-documents") ||
    userData?.user_type === "superadmin" ||
    userData?.user?.can_download_stripe_docs;

  const isStripe = userData?.account?.pos_gateway === "stripe";

  if (
    checkPermission(sideBarPermissions, "manage-clinics") ||
    getIsMainAdmin()
  ) {
    accountSetupSubMenu.push({
      label: "Manage Clinics",
      to: "/settings/clinics",
      match: [
        "/settings/clinics",
        "/settings/clinic/:id/edit",
        "/settings/clinic/create",
      ],
    });
  }

  if (
    checkPermission(sideBarPermissions, "manage-account-information") ||
    getIsMainAdmin()
  ) {
    accountSetupSubMenu.push({
      label: "Business Information",
      to: "/settings/ar-account",
      match: ["/settings/ar-account"],
    });
  }

  accountSetupSubMenu.push({
    label: "Your Profile",
    to: "/settings/profile",
    match: ["/settings/profile"],
  });

  sideBarContent.push({
    icon: "fas angle-down",
    label: "Account Setup",
    content: accountSetupSubMenu,
  });

  if (
    getIsAccountTypePaid() &&
    ((userData && userData.user_type === "superadmin") || getIsPosEnabled())
  ) {
    let posSubMenu = [];
    if (getIsPosEnabled()) {
      if (!getIsTouchMDPracticeBooking()) {
        if (checkPermission(sideBarPermissions, "view-products-inventory")) {
          posSubMenu.push({
            label: "Promotions",
            to: "/settings/discount-packages",
            match: [
              "/settings/discount-packages",
              "/settings/discount-packages/create",
              "/settings/discount-packages/:id/edit",
              "/settings/discount-groups",
              "/settings/discount-groups/create",
              "/settings/discount-groups/:id/edit",
            ],
          });
        }
      }

      if (!getIsTouchMDPracticeBooking()) {
        if (checkPermission(sideBarPermissions, "wallet-settings")) {
          posSubMenu.push({
            label:
              languageData && languageData["sidebar_membership_wallet_menu"],
            to: "/settings/membership-wallet",
            match: ["/settings/membership-wallet"],
          });
        }
      }

      if (
        (userData && userData.user_type === "superadmin") ||
        checkPermission(sideBarPermissions, "dispute-management-access")
      ) {
        posSubMenu.push({
          label: languageData && languageData["sidebar_POS_disputes_only_menu"],
          to: "/settings/pos-disputes/needs-response",
          match: ["/settings/pos-disputes", "/settings/pos-disputes/:type"],
        });
      }
    }

    if (userData && userData.account) {
      if (userData.account.pos_gateway == "stripe") {
        posSubMenu.push({
          label: languageData && languageData["sidebar_POS_settings_menu"],
          to:
            userData.user.user_role_id === 1 &&
            userData.user_type !== "superadmin"
              ? "/settings/pos-dashboard"
              : "/settings/pos",
          match: [
            "/settings/pos",
            "/settings/pos-payment-settings",
            "/settings/pos-dashboard",
            "/settings/pos-payments",
            "/settings/pos-payouts",
          ],
        });

        if (
          userData.user_type == "superadmin" &&
          getIsPosEnabled() &&
          userData.show_clearent_onboarding == 1
        ) {
          posSubMenu.push({
            label: "Clearent Onboarding",
            to: "/settings/pos-onboarding",
            match: ["/settings/pos-onboarding"],
          });
        }
      } else if (
        (userData.user.user_role_id === 1 ||
          userData.user_type == "superadmin") &&
        userData.account.pos_gateway == "clearent"
      ) {
        posSubMenu.push({
          label: languageData && languageData["sidebar_POS_settings_menu"],
          to: "/settings/pos",
          match: [
            "/settings/pos",
            "/settings/pos-payment-settings",
            "/settings/pos-dashboard",
            "/settings/pos-payments",
            "/settings/pos-payouts",
          ],
        });
      }
    }
    if (getIsPosEnabled()) {
      if (!getIsTouchMDPracticeBooking()) {
        if (checkPermission(sideBarPermissions, "view-products-inventory")) {
          posSubMenu.push({
            label: "eGift Cards",
            to: "/settings/e-giftcards",
            match: [
              "/settings/e-giftcards",
              "/settings/e-giftcards/create",
              "/settings/e-giftcards/:id/edit",
            ],
          });

          posSubMenu.push({
            label: "POS quick buttons",
            to: "/settings/pos-quick-button",
            match: [
              "/settings/pos-quick-button",
              "/settings/pos-quick-button/add",
              "/settings/pos-quick-button/:id/edit",
            ],
          });
        }
      }

      if (checkPermission(sideBarPermissions, "view-sales-invoice-text")) {
        posSubMenu.push({
          label: "Invoice Disclaimer",
          to: "/settings/invoice-text",
          match: ["/settings/invoice-text"],
        });
      }
    }
    if (userData && userData.account && userData.account.id == 1937) {
      posSubMenu.push({
        label: "POS Clearent",
        to: "/settings/pos/setup-clearance",
        match: ["/settings/pos/setup-clearance"],
      });
    }

    if (isStripe && canAccessStripeDocuments) {
      posSubMenu.push({
        label: "Stripe Documents",
        to: "/settings/pos-stripe-documents",
        match: ["/settings/pos-stripe-documents"],
      });
    }

    if (posSubMenu && posSubMenu.length > 0) {
      sideBarContent.push({
        icon: "fas angle-down",
        label: "POS",
        content: posSubMenu,
      });
    }
  }

  if (
    checkPermission(sideBarPermissions, "manage-users") ||
    checkPermission(sideBarPermissions, "manage-user-roles") ||
    getIsMainAdmin()
  ) {
    let teammatesSubMenu = [];

    if (
      checkPermission(sideBarPermissions, "manage-users") ||
      getIsMainAdmin()
    ) {
      teammatesSubMenu.push({
        label: languageData && languageData["sidebar_users_menu"],
        to: "/settings/users",
        match: [
          "/settings/users",
          "/settings/users/create",
          "/settings/users/:userId/edit",
          "/settings/user-privileges/:id/role/:roleId",
        ],
      });
    }

    if (!getIsTouchMDPracticeBooking()) {
      if (
        checkPermission(sideBarPermissions, "manage-user-roles") ||
        getIsMainAdmin()
      ) {
        teammatesSubMenu.push({
          label: languageData && languageData["sidebar_userRole_menu"],
          to: "/settings/user-roles",
          match: ["/settings/user-roles"],
        });
      }
    }
    sideBarContent.push({
      icon: "icon-angle-down",
      label: languageData && languageData["sidebar_teammates_menu"],
      content: teammatesSubMenu,
    });
  }

  if (
    userData &&
    userData.user &&
    userData.user.account_id != process.env.REACT_APP_JUVLY_ACC_ID &&
    userData.user.account_id != process.env.REACT_APP_CC_ACC_ID
  ) {
    if (
      checkPermission(sideBarPermissions, "manage-subscription-details") ||
      userData.user_type === "superadmin" ||
      getIsMainAdmin()
    ) {
      let billingSubMenu = [
        {
          label: "Subscriptions",
          to: "/settings/manage-subscription",
          match: ["/settings/manage-subscription"],
        },
      ];
      if (
        getIsAccountTypePaid() === true &&
        userData.account.account_subscription.account_code.indexOf("cus_") > -1
      ) {
        billingSubMenu.push({
          label: "Invoices from AR",
          to: "/settings/manage-invoices",
          match: ["/settings/manage-invoices"],
        });
      }
      billingSubMenu.push({
        label: "Resource Usage",
        to: "/settings/resource-usage",
        match: ["/settings/resource-usage"],
      });
      sideBarContent.push({
        icon: "icon-angle-down",
        label: "Billing",
        content: billingSubMenu,
      });
    }
  }

  let clinicalDocSubMenu = [];

  let clinicalDocSubMenuMath = [];

  if (
    checkPermission(sideBarPermissions, "manage-questionnaires") ||
    getIsMainAdmin()
  ) {
    clinicalDocSubMenuMath = clinicalDocSubMenuMath.concat(
      "/settings/questionnaires",
      "/settings/questionnaires/:id/edit",
      "/settings/questionnaires/create",
      "/settings/procedure-templates",
      "/settings/procedure-template/create",
      "settings/procedure-template/:id/edit",
    );
  }

  if (
    checkPermission(sideBarPermissions, "manage-consents") ||
    getIsMainAdmin()
  ) {
    clinicalDocSubMenuMath = clinicalDocSubMenuMath.concat(
      "/settings/consents",
      "/settings/consent/:id/edit",
      "/settings/consent/create",
    );
  }

  clinicalDocSubMenuMath = clinicalDocSubMenuMath.concat(
    "/settings/membership-agreements",
    "/settings/membership-agreements/:id/edit",
    "/settings/membership-agreements/create",
  );

  if (getIsTouchMDPracticeBooking()) {
    clinicalDocSubMenu.push({
      label: languageData && languageData["title_name_patient_work"],
      to: checkPermission(sideBarPermissions, "manage-questionnaires")
        ? "/settings/procedure-templates"
        : checkPermission(sideBarPermissions, "manage-consents")
        ? "/settings/consents"
        : "",
      match: clinicalDocSubMenuMath,
    });
  } else {
    clinicalDocSubMenu.push({
      label: languageData && languageData["title_name_patient_work"],
      to: checkPermission(sideBarPermissions, "manage-questionnaires")
        ? "/settings/questionnaires"
        : checkPermission(sideBarPermissions, "manage-consents")
        ? "/settings/consents"
        : "/settings/membership-agreements",
      match: clinicalDocSubMenuMath,
    });
  }

  if (
    checkPermission(sideBarPermissions, "manage-pre-treatment-instructions") ||
    checkPermission(sideBarPermissions, "manage-post-treatment-instructions") ||
    getIsMainAdmin()
  ) {
    clinicalDocSubMenu.push({
      label: "Treatment Instructions",
      to: checkPermission(
        sideBarPermissions,
        "manage-pre-treatment-instructions",
      )
        ? "/settings/pre-treatment-instructions"
        : "/settings/post-treatment-instructions",
      match: [
        "/settings/pre-treatment-instructions",
        "/settings/pre-treatment-instructions/:id/edit",
        "/settings/pre-treatment-instructions/create",
        "/settings/pre-treatment-email",
        "/settings/post-treatment-instructions",
        "/settings/post-treatment-instructions/:id/edit",
        "/settings/post-treatment-instructions/create",
        "/settings/post-treatment-email",
      ],
    });
  }

  if (!getIsTouchMDPracticeBooking()) {
    clinicalDocSubMenu.push({
      label: languageData && languageData["sidebar_dot_phrase"],
      to: "/settings/dot-phrases",
      match: [
        "/settings/dot-phrases",
        "/settings/dot-phrases/:actionType/:id?",
      ],
    });

    clinicalDocSubMenu.push({
      label: "Treatment Plan Templates",
      to: "/settings/treatment-plan-templates",
      match: [
        "/settings/treatment-plan-templates",
        "/settings/treatment-plan-templates/create",
        "/settings/treatment-plan-templates/edit/:id",
      ],
    });
  }

  clinicalDocSubMenu.push({
    label: tSettings("efaxTemplates.sidebarMenuTitle"),
    to: ROUTES.settings.efaxTemplates(),
    match: [
      ROUTES.settings.efaxTemplates(),
      ROUTES.settings.createEfaxTemplate(),
      ROUTES.settings.editEfaxTemplate(":id"),
      ROUTES.settings.createEfaxFavorite(),
      ROUTES.settings.editEfaxFavorite(":id"),
    ],
  });

  clinicalDocSubMenu.push({
    label: "Document Expiration",
    to: "/settings/clinical-documentation-settings",
    match: ["/settings/clinical-documentation-settings"],
  });

  if (
    checkPermission(sideBarPermissions, PERMISSIONS.settings.manageChartFilters)
  ) {
    clinicalDocSubMenu.push({
      label: "Chart Filters",
      to: SETTINGS_ROUTES.chartFilters(),
      match: [SETTINGS_ROUTES.chartFilters()],
    });
  }

  sideBarContent.push({
    icon: "icon-angle-down",
    label: "Clinical Documentation",
    content: clinicalDocSubMenu,
  });

  sideBarContent.push({
    label: "Surveys",
    to: "/settings/surveys/list",
    match: [
      "/settings/surveys/list",
      "/settings/surveys/template/create",
      "/settings/surveys/template/:id/edit",
      "/settings/surveys/:id?/view-all",
      "/settings/surveys/view/:surveyID/:id/survey-data",
    ],
  });

  if (!getIsTouchMDPracticeBooking()) {
    if (
      (userData &&
        (userData.user_type == "superadmin" ||
          checkPermission(sideBarPermissions, "view-sales-goals"))) ||
      getIsMainAdmin()
    ) {
      let reportSettingSubMenu = [];
      if (
        (userData.user_type == "superadmin" || getIsMainAdmin()) &&
        getIsPosEnabled()
      ) {
        reportSettingSubMenu.push({
          label: "Payroll/Commission Report",
          to: "/settings/payroll-commission-report",
          match: ["/settings/payroll-commission-report"],
        });
      }

      if (
        (checkPermission(sideBarPermissions, "view-sales-goals") ||
          getIsMainAdmin()) &&
        getIsPosEnabled()
      ) {
        reportSettingSubMenu.push({
          label: "Setup Sales Goals",
          to: "/settings/sales-goals",
          match: [
            "/settings/sales-goals",
            "/settings/sales-goals/add",
            "/settings/sales-goals/:id/:cid/edit",
          ],
        });
      }
      if (
        ((userData && userData.user_type == "superadmin") ||
          getIsMainAdmin()) &&
        getIsPosEnabled()
      ) {
        reportSettingSubMenu.push({
          label: "Dashboard Settings",
          to: "/settings/dashboad-roles",
          match: [
            "/settings/dashboard-roles",
            "/settings/dashboard-users",
            "/settings/dashboard-users/:id/edit",
            "/settings/dashboard-users/create",
            "/settings/dashboad-roles",
          ],
        });
      }

      reportSettingSubMenu.push({
        label: "Referral Sources",
        to: "/settings/referral-sources",
        match: ["/settings/referral-sources"],
      });

      sideBarContent.push({
        icon: "icon-angle-down",
        label: "Report Settings",
        content: reportSettingSubMenu,
      });
    }
  }

  if (!getIsTouchMDPracticeBooking()) {
    const recentlyDeleted = [];

    recentlyDeleted.push({
      label: "Procedures",
      to: SETTINGS_ROUTES.deletedProcedures(),
      match: [SETTINGS_ROUTES.deletedProcedures()],
    });

    recentlyDeleted.push({
      label: "Paperwork",
      to: SETTINGS_ROUTES.deletedPaperwork(),
      match: [SETTINGS_ROUTES.deletedPaperwork()],
    });

    recentlyDeleted.push({
      label: getClientsLabel(),
      to: SETTINGS_ROUTES.deletedPatients(),
      match: [SETTINGS_ROUTES.deletedPatients()],
    });

    recentlyDeleted.push({
      label: "Notes",
      to: SETTINGS_ROUTES.deletedNotes(),
      match: [SETTINGS_ROUTES.deletedNotes()],
    });

    recentlyDeleted.push({
      label: "Surveys",
      to: SETTINGS_ROUTES.deletedSurveys(),
      match: [SETTINGS_ROUTES.deletedSurveys()],
    });

    sideBarContent.push({
      icon: "icon-angle-down",
      label: "Recently Deleted",
      content: recentlyDeleted,
    });
  }

  if (
    (userData &&
      (userData.user_type == "superadmin" ||
        userData.user.user_role_id == 1)) ||
    getIsMainAdmin()
  ) {
    let integrationsMenu = [];

    if (isShopifyAvailable()) {
      integrationsMenu.push({
        label: "Shopify",
        to: SETTINGS_ROUTES.integrations.shopify(),
        match: [SETTINGS_ROUTES.integrations.shopify()],
        isLocked: false,
      });
    }

    integrationsMenu.push({
      label: tSettings("efaxIntegration.title"),
      to: ROUTES.settings.integrations.efax(),
      match: [ROUTES.settings.integrations.efax()],
      isLocked: !getIsEfaxIntegrationAccessible(),
    });

    integrationsMenu.push({
      label: languageData && languageData["label_sidebar_touchmd"],
      to: "/settings/integrations/touch-md",
      match: ["/settings/integrations/touch-md"],
      isLocked: !(getIsAcceleratorPlan() || getIsTouchMDPracticeBooking()),
    });

    integrationsMenu.push({
      label: "Qualiphy",
      to: SETTINGS_ROUTES.integrations.qualiphy(),
      match: [SETTINGS_ROUTES.integrations.qualiphy()],
      isLocked: false,
    });

    integrationsMenu.push({
      label: languageData && languageData["label_sidebar_zoho"],
      to: "/settings/integrations/zoho",
      match: ["/settings/integrations/zoho"],
      isLocked: !getIsAcceleratorPlan(),
    });

    integrationsMenu.push({
      label: languageData && languageData["label_sidebar_hubspot"],
      to: "/settings/integrations/hubspot",
      match: ["/settings/integrations/hubspot"],
      isLocked: !getIsAcceleratorPlan(),
    });

    integrationsMenu.push({
      label: languageData && languageData["label_sidebar_mailchimp"],
      to: "/settings/integrations/mailchimp",
      match: ["/settings/integrations/mailchimp"],
      isLocked: !getIsAcceleratorPlan(),
    });

    integrationsMenu.push({
      label: languageData && languageData["label_sidebar_active_campaign"],
      to: "/settings/integrations/active-campaign",
      match: ["/settings/integrations/active-campaign"],
      isLocked: !getIsAcceleratorPlan(),
    });

    integrationsMenu.push({
      label: languageData && languageData["label_sidebar_constant_contact"],
      to: "/settings/integrations/constant-contact",
      match: ["/settings/integrations/constant-contact"],
      isLocked: !getIsAcceleratorPlan(),
    });

    integrationsMenu.push({
      label: "Zapier",
      to: "/settings/integrations/zapier",
      match: ["/settings/integrations/zapier"],
      isLocked: !getIsAcceleratorPlan(),
    });

    integrationsMenu.push({
      label: "Cherry",
      to: "/settings/integrations/cherry",
      match: ["/settings/integrations/cherry"],
    });

    integrationsMenu.push({
      label: "Leads API",
      to: "/settings/integrations/leads-api",
      match: ["/settings/integrations/leads-api"],
      isLocked: !getIsAcceleratorPlan(),
    });

    integrationsMenu.push({
      label: "Aspire HCP",
      to: "/settings/integrations/aspire",
      match: ["/settings/integrations/aspire"],
      isLocked: !getIsAcceleratorPlan(),
    });

    if (featureToggleAccounts.includes(userData.account.id)) {
      integrationsMenu.push({
        label: "QuickBooks Online",
        to: SETTINGS_ROUTES.integrations.quickBooks(),
        match: [SETTINGS_ROUTES.integrations.quickBooks()],
        isLocked: !getIsAcceleratorPlan(),
      });
    }

    if (userData.account.is_evolus_enabled) {
      integrationsMenu.push({
        label: "Evolus",
        to: "/settings/integrations/evolus",
        match: ["/settings/integrations/evolus"],
      });
    }

    if (integrationsMenu && integrationsMenu.length) {
      sideBarContent.push({
        icon: "icon-angle-down",
        label: languageData && languageData["label_sidebar_integrations"],
        content: integrationsMenu,
      });
    }

    if (toggledOnBetaFutures?.includes("repeat_md")) {
      integrationsMenu.push({
        label: "RepeatMD",
        to: SETTINGS_ROUTES.integrations.repeatMd(),
        match: [SETTINGS_ROUTES.integrations.repeatMd()],
        isLocked: !getIsAcceleratorPlan(),
      });
    }

    integrationsMenu.push({
      label: "Joya",
      to: SETTINGS_ROUTES.integrations.joya(),
      match: [SETTINGS_ROUTES.integrations.joya()],
      isLocked: !getIsAcceleratorPlan(),
    });
  }

  if (
    ((userData && userData.user_type === "superadmin") ||
      checkPermission(sideBarPermissions, "time-clock-settings") ||
      checkPermission(sideBarPermissions, "view-time-clock") ||
      getIsMainAdmin()) &&
    getIsAcceleratorPlan() === true
  ) {
    let clockedMenu = [];

    if (
      (getIsMainAdmin() ||
        checkPermission(sideBarPermissions, "view-time-clock") ||
        (userData && userData.user_type === "superadmin")) &&
      getTimeClockSetting() === true
    ) {
      clockedMenu.push({
        label: "Time Clock",
        to: "/settings/time-clock/list",
        match: ["/settings/time-clock/list"],
      });
    }

    if (
      checkPermission(sideBarPermissions, "time-clock-settings") ||
      (userData && userData.user_type === "superadmin") ||
      getIsMainAdmin()
    ) {
      clockedMenu.push({
        label: "Settings",
        to: "/settings/time-clock/settings",
        match: ["/settings/time-clock/settings"],
      });
    }
    sideBarContent.push({
      icon: "icon-angle-down",
      label: "Time Clock",
      content: clockedMenu,
    });
  }

  if (
    ((userData &&
      (userData.user_type === "superadmin" ||
        userData.user.user_role_id == 1)) ||
      getIsMainAdmin()) &&
    userData.account.account_type === "paid"
  ) {
    let ePrescriptionMenu = [];

    if (ePrescriptionSetup) {
      if (ePrescriptionSetup.is_enrolled == false) {
        ePrescriptionMenu.push({
          label: "Enroll",
          to: "/settings/setup-eprescription",
          match: ["/settings/setup-eprescription"],
        });
      } else {
        ePrescriptionMenu.push({
          label: "Prescribers",
          to: "/settings/eprescription/prescribers",
          match: ["/settings/eprescription/prescribers"],
        });
        ePrescriptionMenu.push({
          label: "Support Users",
          to: "/settings/eprescription/support-users",
          match: ["/settings/eprescription/support-users"],
        });
      }
    }

    sideBarContent.push({
      icon: "icon-angle-down",
      label: "ePrescribing",
      content: ePrescriptionMenu,
    });

    if (featureFlag?.beta_features) {
      sideBarContent.push({
        label: "Beta Program",
        to: SETTINGS_ROUTES.betaProgram(),
        match: [SETTINGS_ROUTES.betaProgram()],
      });
    }
  }

  mainAdminGuard(() => {
    sideBarContent.push({
      label: "Inbox Settings",
      to: "/settings/inbox",
      match: ["/settings/inbox"],
    });
  });

  return sideBarContent;
};

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleClassName: "new-setting-tabs-a",
      sideBarContent: renderSidebar(),
      isPosEnabled: getIsPosEnabled(),
      isAccountTypePaid: getIsAccountTypePaid(),
      isTimeClockSetting: getTimeClockSetting(),
      isAcceleratorPlan: getIsAcceleratorPlan(),
      currentURL: "",
      isResetSideBar: true,
      parentSidebarIndex: null,
      isOpened: 0,
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.handleSideBarClick, false);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const featureFlags = nextProps.globalSettings;
    const betaFeatures = nextProps.betaFeature;
    let returnState = {};
    if (
      getIsPosEnabled() !== prevState.isPosEnabled ||
      getIsAccountTypePaid() !== prevState.isAccountTypePaid ||
      getIsAcceleratorPlan() !== prevState.isAcceleratorPlan ||
      getTimeClockSetting() !== prevState.isTimeClockSetting
    ) {
      returnState.isPosEnabled = getIsPosEnabled();
      returnState.isAccountTypePaid = getIsAccountTypePaid();
      returnState.getIsAcceleratorPlan = getIsAcceleratorPlan();
      returnState.isTimeClockSetting = getTimeClockSetting();
      returnState.sideBarContent = renderSidebar(featureFlags, betaFeatures);
      returnState.isResetSideBar = true;
      returnState.parentSidebarIndex = null;
      returnState.isOpened = 0;
    } else if (nextProps.currentURL !== prevState.currentURL) {
      returnState.currentURL = nextProps.currentURL;
      returnState.sideBarContent = renderSidebar(featureFlags, betaFeatures);
    }

    return returnState;
  }

  componentDidUpdate(prevProps, _) {
    const oldGlobalSettings = prevProps.globalSettings;
    const globalSettings = this.props.globalSettings;
    const oldBetaFeature = prevProps.betaFeature;
    const betaFeatures = this.props.betaFeature;

    if (
      !isEqual(oldGlobalSettings, globalSettings) ||
      !isEqual(
        oldBetaFeature.toggledOnBetaFeaturesLabels,
        betaFeatures.toggledOnBetaFeaturesLabels,
      )
    ) {
      this.setState({
        sideBarContent: renderSidebar(globalSettings, betaFeatures),
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleSideBarClick, false);
  }

  handleSideBarClick = (e) => {
    if (e) {
      let dataset = e.target.dataset;
      if (dataset && dataset.isParentSidebar) {
        if (this.state.parentSidebarIndex != dataset.parentIndex) {
          this.setState({ isResetSideBar: false });
          this.setState({
            isResetSideBar: true,
            parentSidebarIndex: dataset.parentIndex,
            isOpened: dataset.isOpened,
          });
        }
      }
    }
  };

  activeParentLink = (obj, idx) => {
    let returnClass = "parent_class new-setting-subtabs-a";

    if (this.state.parentSidebarIndex == null) {
      if (obj !== undefined) {
        obj.map((io) => {
          if (io.content !== undefined) {
            io.content.map((o) => {
              if (o.match.indexOf(this.props.match.path) > -1) {
                returnClass = "parent_class new-setting-subtabs-a active-menu";
              }
            });
          } else {
            if (io.match !== undefined) {
              if (io.match.indexOf(this.props.match.path) > -1) {
                returnClass = "parent_class new-setting-subtabs-a active-menu";
              }
            }
          }
        });
      }
    } else {
      if (this.state.parentSidebarIndex == idx && this.state.isOpened == 1) {
        returnClass = "parent_class new-setting-subtabs-a active-menu";
      }
    }

    return { returnClass };
  };

  noChildClass = (obj) => {
    let returnClass = "new-setting-tabs-li";

    if (obj !== undefined) {
      obj.map((io) => {
        if (io.match && io.match !== undefined) {
          if (
            this.props.match.path.slice(9).startsWith(io.to.slice(9)) > -1 &&
            this.props.match.url.startsWith(io.to)
          ) {
            if (io.content) {
              returnClass = "new-setting-tabs-li active-menu has-child";
            } else {
              returnClass = "new-setting-tabs-li active-menu no-child";
            }
          }
        }
      });
    }

    return { returnClass };
  };

  setOpen = () => {};

  renderSideBarContent = () => {
    let nav = [];
    const isEfaxEnabled = this.props.globalSettings?.efax_integration;

    nav = this.state.sideBarContent.map((obj, idx) => {
      let toggleClasss = this.activeParentLink([obj], idx).returnClass;
      let parentListClass = this.noChildClass([obj]).returnClass;
      let activeMenu = toggleClasss.indexOf("active-menu") > -1 ? true : false;

      return (
        <Tree
          name={obj.label}
          styleClass={parentListClass}
          styleLinkClass={toggleClasss}
          key={idx}
          childClass={"setting-submenu child"}
          navigateTo={obj.to}
          activeMenu={activeMenu}
          parentIndex={idx}
          isLocked={obj.isLocked}
        >
          {obj.content !== undefined &&
            obj.content.length > 0 &&
            obj.content.map((innerObj, innerIdx) => {
              if (
                innerObj.label ===
                  tSettings("efaxTemplates.sidebarMenuTitle") &&
                !isEfaxEnabled
              ) {
                return null;
              }

              let subMenuClass =
                innerObj.match.indexOf(this.props.match.path) > -1
                  ? "new-setting-subtabs-a sel-submenu"
                  : "new-setting-subtabs-a";
              return (
                <Tree
                  name={innerObj.label}
                  styleClass={"new-setting-subtabs-li"}
                  styleLinkClass={subMenuClass}
                  key={innerIdx}
                  navigateTo={innerObj.to}
                  isLocked={innerObj.isLocked}
                />
              );
            })}
        </Tree>
      );
    });

    return nav;
  };

  render() {
    return (
      <div className="setting-left-menu">
        <div className="setting-title">Settings</div>
        <ul className="new-setting-tabs">
          {this.state.isResetSideBar === true && this.renderSideBarContent()}
        </ul>
      </div>
    );
  }
}

function mapStateToProps() {
  const returnState = {
    isPosEnabled: getIsPosEnabled(),
    isAccountTypePaid: getIsAccountTypePaid(),
    isTimeClockSetting: getTimeClockSetting(),
    isAcceleratorPlan: getIsAcceleratorPlan(),
    currentURL:
      window.location && window.location.pathname
        ? window.location.pathname
        : "",
  };

  return returnState;
}

export default connect(mapStateToProps)(
  withRouter(withBetaFeatures(withGlobalSettings(Sidebar))),
);
