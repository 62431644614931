import { ACTION_TYPES } from "./consts";

export const actions = {
  setInboxTabType: (tab) => ({
    type: ACTION_TYPES.setInboxTabType,
    payload: {
      tab,
    },
  }),
};
