import moment from "moment/moment";
import { map, pipe } from "../../../utilities/fp";

export function prepareEvents(events = []) {
  var filterEvents = (events = []) =>
    events.filter(
      (e) =>
        !(e.appointmentType === "break_time" && moment(e.start).isSame(e.end)),
    );

  var groupByResource = (events = []) =>
    Object.values(
      events.reduce((acc, event) => {
        if (!acc[event.resourceId]) {
          return {
            ...acc,
            [event.resourceId]: [event],
          };
        } else {
          return {
            ...acc,
            [event.resourceId]: [...acc[event.resourceId], event],
          };
        }
      }, {}),
    );

  var groupIntersected = (events = []) => {
    var withoutBreakTime = events.filter((x) => !x.availabilityGrid);
    var withBreakTime = events.filter((x) => x.availabilityGrid);

    withoutBreakTime.sort((x, y) => x.start - y.start);

    var groupedEvents = [withBreakTime];
    var group = [];
    var lastEvent = null;

    withoutBreakTime.forEach((event) => {
      if (lastEvent && lastEvent.end > event.start) {
        group.push(event);
      } else {
        if (group.length) {
          groupedEvents.push(group);
        }
        group = [event];
      }

      lastEvent = event;
    });

    if (group.length) {
      groupedEvents.push(group);
    }

    return groupedEvents;
  };

  var correctZIndexesForGroups =
    (baseZIndex) =>
    (groupedEvents = []) => {
      return groupedEvents.map((group) =>
        group.map((event, index) => ({
          ...event,
          zIndex: event.availabilityGrid ? undefined : baseZIndex + index,
        })),
      );
    };

  return pipe(
    events,
    filterEvents,
    groupByResource,
    map(groupIntersected),
    map(correctZIndexesForGroups(3)),
    (x) => x.flat().flat(),
  );
}
