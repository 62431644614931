import React, { useState } from "react";
import { useRequestInformationFromProviderMutation } from "../../../../api/mutations/useRequestInformationFromProviderMutation";
import { uiNotification } from "../../../../services/UINotificationService";
import styles from "./RequestInfoModal.module.scss";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Modal } from "../../../../shared/Modal/Modal";
import { Button } from "../../../../shared/Button/Button";
import { Textarea } from "../../../../shared/Textarea/Textarea";

export function RequestInfoModal({
  procedureID,
  onClose,
  fetchViewData,
  isOpen,
}) {
  const { tPr } = useAppTranslation.ProviderRoom();
  const { initiate, isLoading } = useRequestInformationFromProviderMutation({
    onSuccess: () => {
      fetchViewData();

      uiNotification.success(tPr("success.requestInfo"));
    },
    onError: () => {
      uiNotification.error(tPr("error.requestInfo"));
    },
  });

  const [requestText, setRequestText] = useState("");

  const handleRequestInfoChange = (e) => {
    const info = e.target?.value;
    setRequestText(info);
  };

  const sendRequest = () => {
    if (requestText) {
      initiate({
        procedureId: Number(procedureID),
        mdNote: requestText,
      });

      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      footer={
        <Button
          isDisabled={isLoading}
          isLoading={isLoading}
          onClick={() => sendRequest()}
        >
          {tPr("label.sendNow")}
        </Button>
      }
      onClose={() => onClose()}
      footerClassName={styles.modalFooterButton}
      header={<Modal.Title>{tPr("label.requestMoreInformation")}</Modal.Title>}
    >
      <div className={styles.requestInfoFormWrapper}>
        <label htmlFor="requestText">{tPr("label.sendChartBack")}</label>
        <Textarea
          id="requestText"
          value={requestText}
          onChange={handleRequestInfoChange}
        />
      </div>
    </Modal>
  );
}
