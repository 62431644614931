import React, { useMemo } from "react";
import { useSalesSummary } from "./hooks/useSalesSummary";
import { useHeaderActions } from "../../../hooks/useHeaderActions";
import Header from "../../../components/Header";
import classes from "../../../newBusinessInsightsContent.module.scss";
import { Table } from "../../../../../shared/Table/Table";
import { Box } from "../../../../../shared/Box/Box";
import summaryClasses from "./scss/salesSummary.module.scss";
import SummaryTable from "./components/SummaryTable";
import { useSalesSummaryReport } from "./hooks/useSalesSummaryReport";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { LoadMoreObserver } from "../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";
import { tBi, useAppTranslation } from "../../../../../i18n/useAppTranslation";
import DownloadDropdown from "../../../components/DownloadDropdown";
import { useTotalSalesReport } from "./hooks/useTotalSalesReport";
import { useDepositSlipReport } from "./hooks/useDepositSlipReport";
import { usePaymentMethodNetReport } from "./hooks/usePaymentMethodNetReport";
import { useSalesFromProceduresPerformedReport } from "./hooks/useSalesFromProceduresPerformedReport";

const modalInfo = {
  title: "salesSummaryReport.info.title",
  rows: [
    "salesSummaryReport.info.totalSalesReport",
    "salesSummaryReport.info.salesFromProceduresAndRetail",
    "salesSummaryReport.info.totalSales",
    "salesSummaryReport.info.netSales",
    "salesSummaryReport.info.depositSlipReport",
    "salesSummaryReport.info.paymentMethodNetReport",
    "salesSummaryReport.info.dailyDiscountReport",
  ],
};

const SalesSummary = () => {
  const { tCommon } = useAppTranslation.Common();
  const { selectedClinic, period, apiPeriod } = useHeaderActions();
  const requestParams = {
    fromDate: apiPeriod.fromDate,
    toDate: apiPeriod.toDate,
    clinic_id:
      selectedClinic.value.value !== 0 ? [selectedClinic.value.value] : [],
  };

  const { data, isLoading } = useSalesSummary(requestParams);
  const {
    data: reportData,
    isLoading: isReportLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useSalesSummaryReport(requestParams);

  const { reportData: totalSalesReportData } = useTotalSalesReport(data);
  const { reportData: depositSlipReportData } = useDepositSlipReport(data);
  const { reportData: paymentMethodNetReportData } =
    usePaymentMethodNetReport(data);
  const { reportData: salesFromProceduresPerformedReport } =
    useSalesFromProceduresPerformedReport(data);

  const tableDataEmpty =
    !isReportLoading && !reportData?.pages[0]?.reportData?.length;

  const tableData = useMemo(
    () =>
      reportData?.pages
        ?.map((p) => p.reportData)
        .flat()
        .map((data) => ({
          patientId: data.patient_id,
          invoiceId: data.invoice_id,
          patient: data.patient_name,
          discountType: data.discount_type,
          provider: data.provider,
          checkoutPerson: data.checkout_person,
          discountAmount: data.amount,
        })),
    [reportData],
  );

  const clinics =
    useMemo(
      () =>
        data?.clinics.map((clinic) => ({
          label: clinic.clinic_name,
          value: clinic.id,
        })),
      [data],
    ) || [];

  const goToInvoice = (row) => {
    window.open(`/sales/invoice/${row.invoiceId}/${row.patientId}`);
  };

  return (
    <div className="flex-col font-14 gap-16">
      <Header
        title="Sales Summary"
        selectedClinic={selectedClinic}
        clinics={clinics}
        period={period}
        requestParams={requestParams}
        downloadRoute={HTTP_ENDPOINTS.getDailySalesReport()}
        modalInfo={modalInfo}
      />
      <div className={summaryClasses.reportsContainer}>
        <SummaryTable
          reportData={totalSalesReportData}
          title={"Total Sales Report"}
          rightTitle={"(All Sources)"}
          isLoading={isLoading}
        />
        <SummaryTable
          reportData={salesFromProceduresPerformedReport}
          title={"Sales from Procedures Performed & Retail"}
          isLoading={isLoading}
        />
        <SummaryTable
          reportData={depositSlipReportData}
          title={"Deposit Slip Report"}
          isLoading={isLoading}
        />
        <SummaryTable
          reportData={paymentMethodNetReportData}
          title={"Payment Method Net Report"}
          isLoading={isLoading}
        />
      </div>
      <div className="flex-col reportTable">
        <Box className="flex justify-between items-center">
          <h3 className={summaryClasses.reportTitle}>
            {tBi("salesSummaryReport.table.label")}
          </h3>
          <DownloadDropdown
            params={requestParams}
            downloadRoute={HTTP_ENDPOINTS.getDailyDiscountReport()}
          />
        </Box>
        <div className={classes.tableContainer}>
          <Table
            bgColor="white"
            cols={[
              { data: tCommon("label.patient"), accessor: "patient" },
              { data: "Discount Type", accessor: "discountType" },
              { data: "Provider", accessor: "provider" },
              { data: "Checkout Person", accessor: "checkoutPerson" },
              { data: "Discount Amount", accessor: "discountAmount" },
            ]}
            data={tableData}
            onRowClick={(row) => goToInvoice(row)}
          />
          {hasNextPage && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={fetchNextPage}
              isLoading={isFetchingNextPage}
            />
          )}
          {isReportLoading && (
            <Box>
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            </Box>
          )}
          {tableDataEmpty && (
            <Box>
              <Empty position="center" />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesSummary;
