import { useState } from "react";
import { http } from "../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";
import { uiNotification } from "../../../../../../services/UINotificationService";

export function useExport({ exportParams, onSuccess }) {
  var { 0: isLoading, 1: setIsLoading } = useState(false);

  var initiate = (clinicOptions) => {
    setIsLoading(true);
    http
      .get(
        `${HTTP_ENDPOINTS.exportInventory()}?scopes=category,productPricePerClinic`,
        {
          params: {
            ...exportParams,
            clinic_id: clinicOptions.map((clinic) => clinic.value),
          },
        },
      )
      .then(() => {
        uiNotification.success(
          "Inventory exported successfully. We will send a separate CSV per clinic to your email",
        );
        onSuccess?.();
      })
      .catch(() => {
        uiNotification.error(
          "Unable to export inventory. Please try again later.",
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    initiate,
  };
}
