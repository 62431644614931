import React, { useRef, useState } from "react";
import cx from "clsx";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Calendar } from "react-date-range";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import { toExactMoment } from "../../../../utilities/date";
import { DEFAULT_DATE_FORMAT } from "../../../../consts/general";
import { useClickOutside } from "../../../../utilities/hooks/useClickOutside";
import calendarIcon from "../../../../_legacy/images/calender.svg";
import styles from "../scss/SingleDateCalendar.module.scss";

export function ProviderSingleDateCalendar({
  className,
  selectedDate,
  onChange,
  hideValue,
}) {
  const rootRef = useRef(null);

  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const { data: user } = useCurrentUserQuery();

  const value = (() => {
    const format = (date) =>
      toExactMoment(date).format(user?.dateFormat || DEFAULT_DATE_FORMAT);
    if (selectedDate) {
      return `${format(selectedDate)}`;
    }
    return "";
  })();

  useClickOutside(rootRef, () => setIsCalendarVisible(false));

  const handleChange = (value) => {
    onChange(value);
    setIsCalendarVisible(false);
  };

  return (
    <div
      ref={rootRef}
      className={cx("memberRightActions", className)}
      onClick={() => setIsCalendarVisible(true)}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          setIsCalendarVisible(true);
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div
        className={cx(
          "search-bg new-calender pull-left",
          styles.calendarContainer,
        )}
      >
        <img src={calendarIcon} alt="calendar" />
        {isCalendarVisible && (
          <Calendar
            className="CalendarPreviewArea"
            date={moment(selectedDate)}
            onChange={(value) => handleChange(value)}
            fromMonth={new Date()}
            disabledDays={{ before: new Date() }}
          />
        )}
        {!hideValue && (
          <input
            type="text"
            className="input-cal setting-search-input p-b-5"
            name="calendar-input"
            value={value}
            autoComplete="off"
            onChange={() => {}}
          />
        )}
      </div>
    </div>
  );
}
