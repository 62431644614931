import moment from "moment";
import React from "react";
import { US_STATES } from "../../../../consts/general";

const GfeClientDetails = ({ appointmentDetails }) => {
  const userDateFormat = localStorage.getItem("dateFormat") || "MM/DD/YYYY";

  const formatUserDate = (date) => {
    return moment.utc(date).format(userDateFormat);
  };

  const stateName = () =>
    US_STATES.find((state) => state.abbr === appointmentDetails?.patient?.state)
      ?.name;

  return (
    <>
      <div className="detail-row">
        <label htmlFor="dob">Date of Birth:</label>
        <span>
          {formatUserDate(appointmentDetails?.patient?.date_of_birth) || ""}
        </span>
      </div>
      <div className="detail-row">
        <label htmlFor="state">State:</label>
        <span>{stateName() || ""}</span>
      </div>
    </>
  );
};

export default GfeClientDetails;
