import React from "react";
import { Modal } from "../../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Button } from "../../../../shared/Button/Button";
import styles from "./ModalNotes.module.scss";

export function ModalNotes({ isOpen, onClose, notes }) {
  const { tPr } = useAppTranslation.ProviderRoom();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      footerNoBorder={true}
      header={
        <Modal.Title>
          {tPr("label.mdRequest")} {notes?.md && `(${notes.md})`}
        </Modal.Title>
      }
      footerClassName={styles.modalFooterButton}
      footer={<Button onClick={onClose}>{tPr("label.ok")}</Button>}
    >
      <div id="modalMdNote" className={styles.mdNote}>
        {notes?.info}
      </div>
    </Modal>
  );
}
