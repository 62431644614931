import { useMemo } from "react";
import { useClinicsQuery } from "../../../../../../api/queries/useClinicsQuery";
import { uiNotification } from "../../../../../../services/UINotificationService";

export function useClinicOptions() {
  var { isLoading, data } = useClinicsQuery({
    onError: () => {
      uiNotification.error("Unable to fetch clinics. Please try again later.");
    },
  });

  var clinicOptions = useMemo(
    () =>
      data?.map((clinic) => ({
        label: clinic.name,
        value: clinic.id,
      })) || [],
    [data],
  );

  return { isLoading, data: clinicOptions };
}
