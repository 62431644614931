import { useMemo, useState } from "react";
import queryString from "query-string";
import { useDebouncedState } from "../../../../../utilities/hooks/useDebouncedState";
import { useLeadArInboxMessagesQuery } from "../../../../../api/queries/useLeadArInboxMessagesQuery";
import { uiNotification } from "../../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { useUpdateLeadArSmsReadStatusMutation } from "../../../../../api/mutations/useUpdateLeadArSmsReadStatusMutation";

export const MESSAGES_TYPE = {
  unread: "unread",
  read: "read",
};

function useReadStatusChange(refetch) {
  const { tInbox } = useAppTranslation.Inbox();

  const [isMarkReadLoading, setIsMarkReadLoading] = useState(false);
  const [isMarkUnreadLoading, setIsMarkUnreadLoading] = useState(false);

  const updateReadStatus = useUpdateLeadArSmsReadStatusMutation();

  const initiate = async (messageIds, isUnread) => {
    try {
      if (isUnread) {
        setIsMarkUnreadLoading(true);
      } else {
        setIsMarkReadLoading(true);
      }
      await updateReadStatus.mutateAsync({
        messageIds,
        isUnread,
      });
      refetch();
    } catch {
      uiNotification.error(tInbox("leadAr.error.updateReadStatus"));
    } finally {
      setIsMarkReadLoading(false);
      setIsMarkUnreadLoading(false);
    }
  };

  return {
    initiate,
    isMarkReadLoading,
    isMarkUnreadLoading,
  };
}

export function useMessages() {
  const queryParams = queryString.parse(window.location.search);
  const clinicId = queryParams?.clinicId;

  const { tInbox } = useAppTranslation.Inbox();

  const [type, setType] = useState(MESSAGES_TYPE.unread);
  const [search, debouncedSearch, setSearch] = useDebouncedState("");

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useLeadArInboxMessagesQuery({
    payload: {
      pageSize: 15,
      unreadOnly: type === MESSAGES_TYPE.unread,
      searchTerm: debouncedSearch,
      clinicId: clinicId ? Number(clinicId) : null,
    },
    options: {
      staleTime: 0,
      keepPreviousData: true,
      onError: () => {
        uiNotification.error(tInbox("leadAr.error.getMessages"));
      },
    },
  });

  const changeReadStatus = useReadStatusChange(refetch);

  const messages = useMemo(() => {
    if (data?.pages) {
      const collected = data.pages
        .map((p) => p.messages)
        .reduce((r, m) => [...r, ...m], []);
      const sorted = [...collected];
      sorted.sort((m1, m2) => m2.isUnread - m1.isUnread);
      return sorted;
    }
    return [];
  }, [data?.pages]);

  const isSoftLoading = isFetching && !isLoading && !isFetchingNextPage;

  return {
    value: messages,
    isLoading,
    isSoftLoading,
    hasMore: Boolean(hasNextPage),
    isNextFetching: isFetchingNextPage,
    fetchMore: fetchNextPage,
    type,
    changeType: setType,
    markRead: {
      isLoading: changeReadStatus.isMarkReadLoading,
      initiate: (messageIds) => changeReadStatus.initiate(messageIds, false),
    },
    markUnread: {
      isLoading: changeReadStatus.isMarkUnreadLoading,
      initiate: (messageIds) => changeReadStatus.initiate(messageIds, true),
    },
    search: {
      value: search,
      onChange: setSearch,
    },
    readCount: data?.pages?.[0]?.readCount || 0,
    unreadCount: data?.pages?.[0]?.unreadCount || 0,
  };
}
