import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";

const requestSchema = yup.object({
  pageSize: yup.number().required(),
  unreadOnly: yup.boolean().required(),
  searchTerm: yup.string(),
  clinicId: yup.number().nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    unreadCount: yup.number().required(),
    allCount: yup.number().required(),
    readCount: yup.number().required(),
    messages: yup.array().of(
      yup.object({
        id: yup.number().required(),
        date: yup.string().nullable(),
        body: yup.string().required(),
        type: yup.string().nullable(),
        patientId: yup.number().required(),
        isUnread: yup.bool().required(),
        author: yup
          .object({
            firstName: yup.string().required(),
            lastName: yup.string().required(),
            imageUrl: yup.string().nullable(),
          })
          .required(),
      }),
    ),
  }),
);

const composeResponse = (res, req) => {
  const data = res?.data?.data?.data || [];
  const counts = res?.data?.data?.notification_counts || {};
  return {
    ...composePaginationSchema({
      response: res?.data?.data || {},
      pageSize: req.pageSize,
    }),
    unreadCount: counts.unread_count || 0,
    allCount: counts.all_count || 0,
    readCount: counts.read_count || 0,
    messages: data.map((message) => ({
      id: message.id,
      date: message.date_added || null,
      body: message.message || "",
      type: message.message_type || null,
      patientId: message.patient_id,
      isUnread: !message.read,
      author: {
        firstName: message.firstname || "",
        lastName: message.lastname || "",
        imageUrl: message.user_image || null,
      },
    })),
  };
};

function createLeadArInboxMessagesQueryKey({
  pageSize,
  unreadOnly,
  searchTerm,
  clinicId,
}) {
  return [
    QUERY_KEYS.leadArInboxMessages,
    pageSize,
    unreadOnly,
    searchTerm,
    clinicId,
  ];
}

export function useLeadArInboxMessagesQuery({ payload, options = {} }) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });
  return useInfiniteQuery({
    queryKey: createLeadArInboxMessagesQueryKey({
      pageSize: req.pageSize,
      unreadOnly: req.unreadOnly,
      searchTerm: req.searchTerm,
      clinicId: req.clinicId,
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.getLeadArInboxMessages(), {
        params: removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          read: req.unreadOnly ? 0 : 1,
          patient_text: req.searchTerm || null,
          clinic_id: req.clinicId,
        }),
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
