import {
  PROCEDURE_TYPE,
  useProcedureCreateUpdateMutation,
} from "../../../../../../../../api/procedure/useProcedureCreateUpdateMutation";
import { history } from "../../../../../../../../history";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { getHealthPathParams } from "../../../HealthCreateEdit.utils";
import { ROUTES } from "../../../../../../../../consts/routes";
import { PATIENT_QUESTIONNAIRE_ENTITY_TYPES } from "../../../../../../../../api/questionnaires/patientQuestionnaires/usePatientQuestionnairesQuery";
import { extractApiError } from "../../../../../../../../utilities/api";

export function useSubmit() {
  const { tCommon } = useAppTranslation.Common();
  const { tClients } = useAppTranslation.Clients();
  const { procedureId, clientId } = getHealthPathParams();
  const createMutation = useProcedureCreateUpdateMutation();

  const initiate = async ({
    form,
    redirectToFillQuestionnaires,
    redirectToSignConsents,
  }) => {
    try {
      const result = await createMutation.mutateAsync({
        id: procedureId,
        patientId: clientId,
        procedureType: PROCEDURE_TYPE.health,
        procedureName: form.chiefComplaint,
        appointmentId: form.appointmentId,
        serviceId: form.serviceId,
        providerId: form.providerId,
        clinicId: form.clinicId,
        procedureDate: form.procedureDate,
        notes: form.notes,
        procedure_notes: form.procedure_notes,
        questionnaireIds: form.questionnaireIds,
        consentIds: form.consentIds,
        type: form.type?.value,
        skinCareCheck: form.skinCareCheck,
      });

      uiNotification.success(tCommon("success.createProcedure"));

      const redirectProcedureId = procedureId || result.data?.data?.id;

      if (redirectToFillQuestionnaires && redirectProcedureId) {
        history.push(
          ROUTES.patientQuestionnaires.manyByEntity({
            type: PATIENT_QUESTIONNAIRE_ENTITY_TYPES.procedure,
            id: redirectProcedureId,
          }),
          {
            backToProfile: true,
          },
        );
      } else if (redirectToSignConsents && redirectProcedureId) {
        history.push(
          ROUTES.patientConsents.manyByEntity({
            type: PATIENT_QUESTIONNAIRE_ENTITY_TYPES.procedure,
            id: redirectProcedureId,
          }),
          {
            backToProfile: true,
          },
        );
      } else {
        history.goBack();
      }
    } catch (e) {
      const error = extractApiError(e);
      uiNotification.error(
        tClients(
          `createEditHealthProcedure.apiError.${error}`,
          tCommon("error.createProcedure"),
        ),
      );
    }
  };

  return {
    initiate,
    isLoading: createMutation.isLoading,
  };
}
