import React, { memo, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import classes from "./Info.module.scss";
import { Skeleton } from "../../../../../../../../../shared/Skeleton/Skeleton";
import { InputLabel } from "../../../../../../../../../shared/InputLabel/InputLabel";
import { useAppTranslation } from "../../../../../../../../../i18n/useAppTranslation";
import { Input } from "../../../../../../../../../shared/Input/Input";
import { Select } from "../../../../../../../../../shared/Select/Select";
import { InputError } from "../../../../../../../../../shared/InputError/InputError";
import { DateSelect } from "../../../../../../shared/DateSelect/DateSelect";
import { useAppointmentOptions } from "../../../../../../hooks/useAppointmentOptions";
import { getHealthPathParams } from "../../../../HealthCreateEdit.utils";
import { useProviderOptions } from "../../../../../../hooks/useProviderOptions";
import { useClinicOptions } from "../../../../../../hooks/useClinicOptions";
import { useServiceOptions } from "../../../../../../hooks/useServiceOptions";
import { useChartingFilterCategoriesQuery } from "../../../../../../../../../api/queries/useChartingFilterCategoriesQuery";
import { Checkbox } from "../../../../../../../../../shared/Checkbox/Checkbox";
import { withGlobalSettings } from "../../../../../../../../../hocs/withGlobalSettings";

export const Info = withGlobalSettings(
  memo(
    ({
      chiefComplaint,
      appointmentId,
      serviceId,
      providerId,
      clinicId,
      procedureDate,
      isLoading,
      type,
      skinCareCheck,
      globalSettings,
    }) => {
      const { tClients } = useAppTranslation.Clients();
      const { clientId, procedureId, isEditMode } = getHealthPathParams();

      const appointmentOptions = useAppointmentOptions({
        clientId,
        procedureId,
      });

      const selectedAppointment = appointmentOptions.data.find(
        (a) => a.value === appointmentId.value,
      );

      const serviceOptions = useServiceOptions({
        appointmentServiceIds: selectedAppointment?.serviceIds,
      });

      const providerOptions = useProviderOptions();

      const clinicOptions = useClinicOptions({
        providerId: providerId.value,
        onProviderClinicsFetched: clinicId.validateWithProviderClinics,
      });

      const chartingFilters = useChartingFilterCategoriesQuery();

      const chartingFilterOptions = useMemo(() => {
        return (
          chartingFilters.data?.map((category) => ({
            label: category.name,
            value: category.id,
          })) || []
        );
      }, [chartingFilters.data]);

      const isJoyaAvailable = globalSettings?.joya?.isAvailable || false;

      useEffect(() => {
        if (!procedureDate.value) {
          procedureDate.onChange(new Date());
        }
      }, []);

      return isLoading ? (
        <div className={classes.root}>
          {Array.from(Array(5).keys()).map((i) => (
            <Skeleton key={i} count={1} height={50} />
          ))}
        </div>
      ) : (
        <>
          <div className={classes.root}>
            <div>
              <InputLabel>
                {tClients("createEditHealthProcedure.info.chiefComplaint")}{" "}
                {isEditMode && (
                  <span className={classes.requiredAsterisk}>*</span>
                )}
              </InputLabel>
              <Input
                value={chiefComplaint.value || ""}
                onChange={(e) => chiefComplaint.onChange(e.target.value)}
                isError={chiefComplaint.getError()}
              />
              {Boolean(chiefComplaint.getError()) && (
                <InputError>{chiefComplaint.getError()}</InputError>
              )}
            </div>
            <div>
              <InputLabel>
                {tClients("createEditHealthProcedure.info.selectAppointment")}
              </InputLabel>
              <Select
                isClearable
                value={
                  appointmentOptions.data.find(
                    (i) => i.value === appointmentId.value,
                  ) || null
                }
                isLoading={appointmentOptions.isLoading}
                options={appointmentOptions.data}
                onChange={(option) =>
                  appointmentId.onChange(option, serviceOptions.data)
                }
              />
            </div>
            <div>
              <InputLabel>
                {tClients("createEditCosmeticProcedure.info.selectService")}
                {Boolean(appointmentId.value) && (
                  <span className={classes.requiredAsterisk}> *</span>
                )}
              </InputLabel>
              <Select
                isSearchable
                isClearable
                value={
                  serviceOptions.data.find(
                    (i) => i.value === serviceId.value,
                  ) || null
                }
                options={serviceOptions.data}
                onChange={serviceId.onChange}
                isError={Boolean(serviceId.getError())}
                isLoading={serviceOptions.isLoading}
              />
              {Boolean(serviceId.getError()) && (
                <InputError>{serviceId.getError()}</InputError>
              )}
            </div>
            <div>
              <InputLabel>
                {tClients("createEditHealthProcedure.info.selectProvider")}{" "}
                <span className={classes.requiredAsterisk}>*</span>
              </InputLabel>
              <Select
                isSearchable
                value={
                  providerOptions.data.find(
                    (i) => i.value === providerId.value,
                  ) || null
                }
                isLoading={providerOptions.isLoading}
                options={providerOptions.data}
                onChange={providerId.onChange}
                isError={Boolean(providerId.getError())}
              />
              {Boolean(providerId.getError()) && (
                <InputError>{providerId.getError()}</InputError>
              )}
            </div>
            <div>
              <InputLabel>
                {tClients("createEditHealthProcedure.info.selectClinic")}{" "}
                <span className={classes.requiredAsterisk}>*</span>
              </InputLabel>
              <Select
                isSearchable
                value={
                  clinicOptions.data.find((i) => i.value === clinicId.value) ||
                  null
                }
                isLoading={clinicOptions.isLoading}
                options={clinicOptions.data}
                onChange={clinicId.onChange}
                isError={Boolean(clinicId.getError())}
              />
              {Boolean(clinicId.getError()) && (
                <InputError>{clinicId.getError()}</InputError>
              )}
            </div>
            <div>
              <InputLabel>
                {tClients("createEditHealthProcedure.info.procedureDate")}
              </InputLabel>
              <DateSelect
                value={procedureDate.value}
                onChange={procedureDate.onChange}
              />
            </div>
            <div>
              <InputLabel>
                {tClients("createEditHealthProcedure.info.type")}
              </InputLabel>
              <Select
                value={type.value}
                onChange={(next) => type.onChange(next)}
                options={chartingFilterOptions}
              />
            </div>
          </div>
          {isJoyaAvailable && (
            <div>
              <Checkbox
                label="Skin Care Check"
                isChecked={skinCareCheck.value}
                onChange={() => skinCareCheck.onChange(!skinCareCheck.value)}
              />
            </div>
          )}
        </>
      );
    },
  ),
);

Info.propTypes = {
  chiefComplaint: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  appointmentId: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func,
  }).isRequired,
  serviceId: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func,
    getError: PropTypes.func,
  }).isRequired,
  providerId: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func,
    getError: PropTypes.func,
  }).isRequired,
  clinicId: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func,
    getError: PropTypes.func,
  }).isRequired,
  procedureDate: PropTypes.shape({
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
};
