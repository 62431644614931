import React, { useState } from "react";
import cx from "clsx";
import { Trans } from "react-i18next";
import { Box } from "../../../shared/Box/Box";
import ClinicDropdown from "./ClinicDropdown";
import { DateRangePicker } from "../../../shared/DateRangePicker/DateRangePicker";
import { useCurrentUserQuery } from "../../../api/queries/useUserQuery";
import { Select } from "../../../shared/Select/Select";
import classes from "../newBusinessInsightsContent.module.scss";
import DownloadDropdown from "./DownloadDropdown";
import { Modal } from "../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { Button } from "../../../shared/Button/Button";
import { svg } from "../../../assets/svg";
import { ChevronLeftIcon } from "../../../assets/Icons/ChevronLeftIcon";
import { history } from "../../../history";

const Header = ({
  title,
  clinics,
  selectedClinic,
  period,
  reportTypeOptions,
  handleChangeReportType,
  reportType,
  downloadRoute,
  requestParams,
  modalInfo,
  withBackBtn,
  backFunc,
}) => {
  const { tBi } = useAppTranslation.BusinessInsights();
  const { data: user } = useCurrentUserQuery();
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  return (
    <Box className={cx("m-b-20 membership-title", classes.headerBox)}>
      <div className="flex items-center gap-8 m-b-5">
        {withBackBtn && (
          <Button
            variant="text"
            size="small"
            onClick={() => (backFunc ? backFunc() : history.goBack())}
          >
            <ChevronLeftIcon />
          </Button>
        )}
        {title}
        {modalInfo && (
          <Button
            size="small"
            variant="text"
            className={classes.infoButton}
            onClick={() => setIsInfoModalOpen(true)}
          >
            <img src={svg.infoPrimary} alt="info" width="20px" />
          </Button>
        )}
      </div>
      <div className={cx(classes.headerActions)}>
        {clinics && (
          <ClinicDropdown options={clinics} selectedClinic={selectedClinic} />
        )}
        {reportTypeOptions && (
          <Select
            size={"small"}
            options={reportTypeOptions}
            onChange={(options) => handleChangeReportType(options)}
            value={reportType}
            className={classes.reportTypeSelect}
          />
        )}
        {period && (
          <DateRangePicker
            size="small"
            dateFormat={user?.dateFormat}
            onChange={({ startDate, endDate }) => {
              period.update({
                fromDate: startDate,
                toDate: endDate,
              });
            }}
            value={{
              startDate: period.value.fromDate,
              endDate: period.value.toDate,
            }}
          />
        )}
        <DownloadDropdown
          params={requestParams}
          downloadRoute={downloadRoute}
        />
      </div>
      <Modal
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
        header={<Modal.Title>{tBi(modalInfo?.title)}</Modal.Title>}
        contentClassName={classes.infoModalContent}
      >
        {modalInfo?.rows.map((key) => (
          <p key={key}>
            <Trans
              i18nKey={tBi(key)}
              components={{
                b: <b />,
              }}
            />
          </p>
        ))}
      </Modal>
    </Box>
  );
};

export default Header;
