import React from "react";
import PropTypes from "prop-types";
import { Tabs } from "../../../../shared/Tabs/Tabs";
import classes from "./ProcedureTabs.module.scss";
import { PROVIDER_ROOM_PROCEDURE_TYPE } from "../../../../api/queries/useProviderRoomProceduresQuery";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";

export function ProcedureTabs({ value, onChange, isInfoTabVisible }) {
  const { tCommon } = useAppTranslation.Common();
  const tabs = [
    {
      label: tCommon("providerRoom.procedureTabs.cosmetic"),
      type: PROVIDER_ROOM_PROCEDURE_TYPE.cosmetic,
    },
    {
      label: tCommon("providerRoom.procedureTabs.health"),
      type: PROVIDER_ROOM_PROCEDURE_TYPE.health,
    },
    {
      label: tCommon("providerRoom.procedureTabs.information"),
      type: PROVIDER_ROOM_PROCEDURE_TYPE.information,
    },
  ];

  const filteredTabs = (() => {
    if (isInfoTabVisible) {
      return tabs;
    }

    return tabs.filter(
      (tab) => tab.type !== PROVIDER_ROOM_PROCEDURE_TYPE.information,
    );
  })();

  return (
    <Tabs
      className={classes.root}
      tabClassName={classes.tab}
      active={value}
      options={filteredTabs.map((tab) => ({
        label: tab.label,
        value: tab.type,
        onClick: () => onChange(tab.type),
      }))}
    />
  );
}

ProcedureTabs.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isInfoTabVisible: PropTypes.number,
};
