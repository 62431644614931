import React from "react";
import classes from "../../Clients.module.scss";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Table } from "../../../../shared/Table/Table";

const RepeatMdMembershipBenefits = () => {
  const { tCommon } = useAppTranslation.Common();
  const { tClients } = useAppTranslation.Clients();

  const tableData = [];

  return (
    <>
      <div className="juvly-container flex justify-between">
        <h1 className={classes.repeatMdTitle}>
          {tClients("repeatMd.benefits.title")}
        </h1>
      </div>
      <div className={classes.tableWrapper}>
        <Table
          bgColor="white"
          keyExtractor={(row) => row?.id}
          colClassName={classes.clientWalletTableRow}
          headColClassName={classes.clientWalletTableHead}
          bodyClassName={classes.clientWalletTableBody}
          data={[]}
          cols={[
            {
              data: tClients("repeatMd.benefits.table.packageProduct"),
              accessor: "item",
            },
            {
              data: tClients("repeatMd.benefits.table.clinic"),
              accessor: "clinic",
            },
            {
              data: tClients("repeatMd.benefits.table.balanceUnits"),
              accessor: "balanceUnits",
            },
          ]}
        />
        {!tableData?.length && (
          <p className={classes.noRecord}>{tCommon("label.noRecordFound")}</p>
        )}
      </div>
    </>
  );
};

export default RepeatMdMembershipBenefits;
